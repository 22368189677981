<template>
	<!-- Start Template Root Div -->
	<div>
		<header class="w-full sticky top-0 z-10">
			<div class="w-full bg-gray-700">
				<div class="container mx-auto py-2 lg:p-8 lg:py-3">
					<div
						class="w-full flex justify-between items-center p-2 text-white"
					>
						<div class="w-1/4 text-white p-5">
							<WaveLogo
								class="cursor-pointer h-16 md:h-12 lg:h-16"
							/>
						</div>
						<div class="hidden md:block">
							<h1 class="text-2xl">Sign Waiver</h1>
						</div>
						<div class="w-1/4 text-right">
							<p class="m-0">
								{{ new Date() | moment("dddd Do MMMM") }}
							</p>
						</div>
					</div>
				</div>
			</div>
		</header>

		<main class="container mx-auto py-2 lg:p-8 lg:py-3">
			<!--content-->
			<div
				class="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none mb-10"
			>
				<!--header-->
				<div
					class="hidden md:flex border-b border-solid border-gray-300 rounded-t"
				>
					<p
						class="flex-auto p-5 text-2xl text-gray-300 font-semibold border-r"
					>
						1. Acknowledgement Of Risk And Waiver
					</p>
					<p
						class="flex-auto p-5 text-2xl text-gray-300 font-semibold border-r"
					>
						2. Sign Waiver
					</p>
					<p
						class="flex-auto p-5 text-2xl text-gray-300 font-semibold"
					>
						3. Watch Video
					</p>
				</div>

				<!--body-->
				<div class="relative p-6 flex-auto">
					<div
						v-if="reservation != undefined"
						class="flex flex-col items-center justify-around m-8 lg:mb-0"
					>
						<!-- - Select List - -->
						<div
							v-for="(surferAdult,
							index) in reservation.waiversAdults"
							:key="'surferAdult-' + index"
							class="w-full"
						>
							<!-- - Tick - -->
							<div class="md:w-10/12 lg:w-8/12 mx-auto">
								<input
									type="radio"
									:id="'surferAdult-' + surferAdult.firstName"
									class="hidden"
									v-model="waiverSelected"
									:value="surferAdult.firstName"
								/>
								<label
									v-bind:for="
										'surferAdult-' + surferAdult.firstName
									"
									class="m-8 p-4 rounded bg-cool-gray-500 text-white flex justify-around items-center cursor-pointer"
								>
									<div>
										<!-- - Adult / Child Named Text - -->
										<h3 class="capitalize">
											{{ surferAdult.firstName }}
											{{ surferAdult.lastName }}
										</h3>

										<!-- - Event Details - -->
										<h4 class="text-base">
											{{ surferAdult.surfName }}
										</h4>
										<h5 class="text-sm">
											{{ surferAdult.emailAddress }}
										</h5>
									</div>

									<!-- - Toggle Box - -->
									<div class="flex flex-col items-center">
										<h4 class="text-base pb-3">
											This Is Me
										</h4>
										<span
											role="radio"
											tabindex="0"
											aria-checked="false"
											class="relative inline-flex items-center justify-center h-10 w-10 rounded-full bg-gray-200 text-gray-800 shadow focus:outline"
										>
											<div
												class="h-6 w-6 bg-cool-gray-600 rounded-full transition-opacity ease-in-out duration-200"
												:class="
													waiverSelected ===
													surferAdult.firstName
														? ''
														: 'opacity-0'
												"
											></div>
										</span>
									</div>
								</label>
							</div>
						</div>
					</div>
				</div>

				<!-- Footer -->
				<div class="w-full flex mb-12 justify-center items-center">
					<button
						@click="checkSelected"
						class="w-9/12 md:w-6/12 lg:w-3/12 bg-pear-500 hover:bg-pear-700 text-sherpa-500 text-center text-sm font-bold py-2 px-3 rounded-full"
					>
						Proceed with Selected Surfer
					</button>
				</div>
			</div>
		</main>
	</div>
</template>

<script>
	import WaveLogo from "@/components/WaveLogo.vue";
	import { mapActions } from "vuex";

	export default {
		components: {
			WaveLogo
		},
		data: () => ({
			validEmail: false,
			emailAddress: "",
			waivers: [],
			reservation: undefined,
			waiverSelected: ""
		}),
		created() {
			this.checkData();
		},
		methods: {
			// Vuex Mapped Actions from reservations Namespace:
			...mapActions({
				getReservation: "reservations/fetchReservation"
			}),
			checkSelected() {
				// Set Reservation Waiver First Name
				this.reservation.setWaiverFirstName(this.waiverSelected);

				// Check if they have already completed or not
				if (this.reservation.getWaiversSignable().length < 1) {
					this.$router.push({
						name: "SurferWaiverConfirm",
						query: this.$route.query
					});
					return;
				}

				// Redirect Route
				this.$router.push({
					name: "SurferWaiverDisclaimer",
					query: this.$route.query
				});
			},
			async checkData() {
				// Setup Plugin & Params
				await this.setupData();

				// Re-append Route & Redirect if missing query params
				if (
					!this.validEmail ||
					this.reservation.getWaiversSignable().length < 1
				)
					this.$router.push({
						name: "SurferWaiver",
						query: this.$route.query
					});
			},
			async setupData() {
				// Call Plugin
				if (this.$route.query.emailAddress) {
					// Parse Email Address
					this.emailAddress = this.$route.query.emailAddress
						? this.$route.query.emailAddress
								.replace("..", "@")
								.toLowerCase()
						: "";

					// Get Waivers
					this.reservation = await this.getReservation(
						this.emailAddress
					);
					this.waivers = this.reservation.waivers;

					// Check Email Addresses
					if (
						this.emailAddress ==
						this.reservation.primaryEmailAddress
					)
						this.validEmail = true;

					// Default Radio Selected
					if (this.validEmail)
						this.waiverSelected = this.reservation.waiversAdults[0].firstName;
				}
			}
		}
	};
</script>
